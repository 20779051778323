/** @constant {string} action for redirecting to either an external URL or internal page. */
export const REDIRECT = "CUDA/REDIRECT";

/**
 * Creates the REDIRECT action, for redirecting to either an external URL or internal page.
 *
 * @function
 * @param {string} pathOrUrl the internal relative path, or external URL to navigate to.
 * @returns {object} action, ready to be used in a redux dispatch.
 */
export const redirect = (pathOrUrl: string) => ({
    type: REDIRECT,
    pathOrUrl
});