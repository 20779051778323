import {TOptions} from "i18next";

/** action type for displaying a notification */
export const NOTIFICATION_SHOW = "CUDA/NOTIFICATION_SHOW" as const;
/** action type for hiding a notification */
export const NOTIFICATION_HIDE = "CUDA/NOTIFICATION_HIDE" as const;
/** banner notification type */
export const BANNER_NOTIFICATION = "CUDA/BANNER_NOTIFICATION" as const;
/** dialog notification type */
export const DIALOG_NOTIFICATION = "CUDA/DIALOG_NOTIFICATION" as const;
/** toast notification type */
export const TOAST_NOTIFICATION = "CUDA/TOAST_NOTIFICATION" as const;
/** info notification level */
export const INFO_NOTIFICATION = "CUDA/INFO_NOTIFICATION" as const;
/** ok notification level */
export const OK_NOTIFICATION = "CUDA/OK_NOTIFICATION" as const;
/** warning notification level */
export const WARNING_NOTIFICATION = "CUDA/WARNING_NOTIFICATION" as const;
/** error notification level */
export const ERROR_NOTIFICATION = "CUDA/ERROR_NOTIFICATION" as const;

export type NOTIFICATION_TYPE = typeof BANNER_NOTIFICATION | typeof DIALOG_NOTIFICATION | typeof TOAST_NOTIFICATION;
export type NOTIFICATION_LEVEL =
    typeof INFO_NOTIFICATION
    | typeof OK_NOTIFICATION
    | typeof WARNING_NOTIFICATION
    | typeof ERROR_NOTIFICATION;

export type BaseNotification = {
    type: NOTIFICATION_TYPE,
    translateParams?: TOptions
}
export type BannerNotification = BaseNotification & {
    type: typeof BANNER_NOTIFICATION,
    content?: string,
    level?: NOTIFICATION_LEVEL
    dismissible?: boolean,
    spinner?: boolean,
    translateParams?: any
};
export type DialogNotification = BaseNotification & {
    type: typeof DIALOG_NOTIFICATION,
    content?: string | [string]
};
export type ToastNotification = BaseNotification & {
    type: typeof TOAST_NOTIFICATION,
    content?: string,
    level?: NOTIFICATION_LEVEL,
    duration?: number
};
export type Notification = BannerNotification | DialogNotification | ToastNotification;
export type NOTIFICATION_SHOW_ACTION = { type: typeof NOTIFICATION_SHOW, notification: Notification };
export type NOTIFICATION_HIDE_ACTION = {
    type: typeof NOTIFICATION_HIDE,
    notification: Pick<Notification, "type" | "content">
};

export type NOTIFICATION_ACTION = NOTIFICATION_SHOW_ACTION | NOTIFICATION_HIDE_ACTION;

/**
 * Creates the NOTIFICATION_SHOW action, for displaying a notification of the BANNER_NOTIFICATION type.
 *
 * @function
 * @param content the content to display in the banner.
 * @param level the notification level.
 * @param dismissible sets whether the banner is dismissible by the user or not.
 * @param spinner sets whether a spinner should show in the banner or not.
 * @param translateParams optional object to provide to the translate function, when translating the content.
 * @returns action, ready to be used in a redux dispatch.
 */
export const notificationShowBanner = (content: string, level: NOTIFICATION_LEVEL = INFO_NOTIFICATION, dismissible = true, spinner = false, translateParams?: TOptions): NOTIFICATION_SHOW_ACTION => ({
    type: NOTIFICATION_SHOW,
    notification: {
        type: BANNER_NOTIFICATION,
        content,
        level,
        dismissible,
        spinner,
        translateParams
    }
});

/**
 * Creates the NOTIFICATION_SHOW action, for displaying a notification of the DIALOG_NOTIFICATION type.
 *
 * @function
 * @param content the content to display in the banner.
 * @param translateParams optional object to provide to the translate function, when translating the content.
 * @returns action, ready to be used in a redux dispatch.
 */
export const notificationShowDialog = (content: string, translateParams?: TOptions): NOTIFICATION_SHOW_ACTION => ({
    type: NOTIFICATION_SHOW,
    notification: {
        type: DIALOG_NOTIFICATION,
        content,
        translateParams
    }
});

/**
 * Creates the NOTIFICATION_SHOW action, for displaying a notification of the TOAST_NOTIFICATION type.
 *
 * @function
 * @param content the content to display in the banner.
 * @param level the notification level.
 * @param duration sets the duration (in ms) that the toast is visible for.
 * @param translateParams optional object to provide to the translate function, when translating the content.
 * @returns action, ready to be used in a redux dispatch.
 */
export const notificationShowToast = (content: string, level: NOTIFICATION_LEVEL = INFO_NOTIFICATION, duration: number = 10000, translateParams?: TOptions): NOTIFICATION_SHOW_ACTION => ({
    type: NOTIFICATION_SHOW,
    notification: {
        type: TOAST_NOTIFICATION,
        content,
        level,
        duration,
        translateParams
    }
});

/**
 * Creates the NOTIFICATION_HIDE action, for hiding a notification.
 *
 * @function
 * @param type the notification type to close.
 * @param content the current content of the notification you want to close. If not provided, all notifications of the matching type will be closed.
 * @returns action, ready to be used in a redux dispatch.
 */
export const notificationHide = (type: NOTIFICATION_TYPE, content?: string | [string]): NOTIFICATION_HIDE_ACTION => ({
    type: NOTIFICATION_HIDE,
    notification: {type, content}
});