import {enhanceActionWithGlobalParams} from "./GlobalParamsActions";

/** @constant {string} action type for authentication check */
export const AUTH_CHECK = "CUDA/AUTH_CHECK";
/** @constant {string} action type for authentication error event */
export const AUTH_ERROR = "CUDA/AUTH_ERROR";
/** @constant {string} action type for authentication login event */
export const AUTH_LOGIN = "CUDA/AUTH_LOGIN";
/** @constant {string} action type for authentication change account event */
export const AUTH_CHANGE_ACCOUNT = "CUDA/AUTH_CHANGE_ACCOUNT";
/** @constant {string} action type for authentication logout event */
export const AUTH_LOGOUT = "CUDA/AUTH_LOGOUT";
/** @constant {string} action type for saving origin (used by authentication flow to redirect back after login). */
export const SAVE_ORIGIN = "CUDA/SAVE_ORIGIN";
/** @constant {string} action type for saving a target login URL (used by authentication flow to override the login url for the next login attempt). */
export const SAVE_LOGIN_URL = "CUDA/SAVE_LOGIN_URL";

/**
 * Creates the AUTH_CHECK action, with the provided "params", and enhances it with the current GlobalParams.
 *
 * @function
 * @param params the params to pass to the authClient.
 * @returns thunk action, ready to be used in a redux dispatch.
 */
export const authCheck = (params: any) => enhanceActionWithGlobalParams({
    type: AUTH_CHECK,
    params
});

/**
 * Creates the AUTH_ERROR action, with the provided "params", and enhances it with the current GlobalParams.
 *
 * @function
 * @param  params the params to pass to the authClient.
 * @returns thunk action, ready to be used in a redux dispatch.
 */
export const authError = (params: any) => enhanceActionWithGlobalParams({
    type: AUTH_ERROR,
    params
});

/**
 * Creates the AUTH_LOGIN action, with the provided "params", and enhances it with the current GlobalParams.
 *
 * @function
 * @param params the params to pass to the authClient.
 * @returns thunk action, ready to be used in a redux dispatch.
 */
export const authLogin = (params: any) => enhanceActionWithGlobalParams({
    type: AUTH_LOGIN,
    params
});

/**
 * Creates the AUTH_CHANGE_ACCOUNT action, with the provided "accountId", and enhances it with the current GlobalParams.
 *
 * @function
 * @param accountId the id of the account to switch to
 * @param updateInPlace if true, account is updated via background fetch request, otherwise the app is reloaded.
 * @returns thunk action, ready to be used in a redux dispatch.
 */
export const authChangeAccount = (accountId: string, updateInPlace?: boolean) => enhanceActionWithGlobalParams({
    type: AUTH_CHANGE_ACCOUNT,
    params: {accountId, updateInPlace}
});

/**
 * Creates the AUTH_LOGOUT action, and enhances it with the current GlobalParams.
 *
 * @function
 * @returns thunk action, ready to be used in a redux dispatch.
 */
export const authLogout = () => enhanceActionWithGlobalParams({
    type: AUTH_LOGOUT
});

/**
 * Creates the SAVE_ORIGIN, to save the provided origin URL path.
 *
 * @function
 * @param origin the origin to be saved.
 * @returns action, ready to be used in a redux dispatch.
 */
export const saveOrigin = (origin: string | null) => ({
    type: SAVE_ORIGIN,
    origin
});

/**
 * Creates the SAVE_LOGIN_URL, to save the provided URL path for the next login attempt.
 *
 * @function
 * @param loginUrl the login URL to be saved.
 * @returns action, ready to be used in a redux dispatch.
 */
export const saveLoginUrl = (loginUrl: string) => ({
    type: SAVE_LOGIN_URL,
    loginUrl
});