import {
    DIALOG_NOTIFICATION,
    NOTIFICATION_HIDE,
    NOTIFICATION_SHOW,
    NOTIFICATION_ACTION,
    Notification
} from "../../actions";
import {uniqBy} from "lodash";

export type NotificationReducerState = {
    [key: string]: any
};
type Action = NOTIFICATION_ACTION;

const NotificationReducer = (previousState: NotificationReducerState = {}, {type, notification}: Action): NotificationReducerState => {
    switch (type) {
        case NOTIFICATION_SHOW:
            if (notification.type === DIALOG_NOTIFICATION) {
                return Object.assign(
                    {},
                    previousState,
                    {
                        [notification.type]: notification
                    }
                );
            }
            return Object.assign(
                {},
                previousState,
                {
                    [notification.type]: uniqBy([...(previousState[notification.type] || []), notification], ({content}) => content)
                }
            );
        case NOTIFICATION_HIDE:
            if (notification.type === DIALOG_NOTIFICATION || !notification.content) {
                return Object.assign({}, previousState, {[notification.type]: null});
            }
            return Object.assign({}, previousState, {
                [notification.type]: (previousState[notification.type] || []).filter((existing: Notification) => existing.content !== notification.content)
            });
        default:
            return previousState;
    }
};

export default NotificationReducer;