import {isEmpty, isString, set, uniq, uniqBy} from "lodash";
import {format, isAfter, isBefore, parseISO} from "date-fns";
import React, {Fragment} from "react";
import {useDeepCompareMemoize} from "../hooks";

export type Validator = (value: any, allValues: formValues, props: formProps, source?: string) => undefined | string | string[];

/**
 * Interface for form validation functions, that can be passed to Form/TabbedForm children as the value, or in an array value for the "validate" prop.
 *
 * These functions are usually called by Input.
 *
 * @typedef formValidator
 * @function
 * @param {*} value the current value
 * @param {object} allValues all the values for the current form
 * @param {object} props the props object, should contain the withTranslation "t" method for translating.
 * @returns {string|undefined} either an error message if validation fails, or undefined
 */

// Note: IP addresses in 0.0.0.0/8 and 255.0.0.0/8 are not valid
export const ipv4Regex = /^((0|1\d?\d?|2[0-4]?\d?|25[0-5]?|[3-9]\d?)\.){3}(0|1\d?\d?|2[0-4]?\d?|25[0-5]?|[3-9]\d?)$/;
export const privateIpv4Regex = /^((10(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){3})|(172\.(1[6-9]|2\d|3[01])(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})|(192\.168(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2}))$/;
export const ipv4CIDRRegex = /^(?:25[0-4]|2[0-4]\d|1\d\d|[1-9]\d?)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}\/(?:3[0-2]|[1-2]\d|[1-9])$/;
export const privateIpv4CIDR10Regex = /^(10(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){3})\/(?:3[0-2]|[1-2]\d|[8-9])$/;
export const privateIpv4CIDR172Regex = /^(172\.(1[6-9]|2\d|3[01])(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})\/(?:3[0-2]|2[0-9]|1[2-9])$/;
export const privateIpv4CIDR192Regex = /^(192\.168(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})\/(?:3[0-2]|2[0-9]|1[6-9])$/;
export const numberRegex = /^(\d+)$/;
export const emailRegex = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
export const domainRegex = /^((?=[a-zA-Z0-9-]{1,63}\.)(xn--)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,63}$/;
export const wildcardDomainRegex = /^(([a-zA-Z0-9*]([*a-zA-Z0-9-]{0,61}[a-zA-Z0-9*])?)\.)+([a-zA-Z*]([*a-zA-Z0-9-]{0,61}[a-zA-Z0-9*])?)$/;

type formValues = {
    [key: string]: any
} | undefined;
type formProps = {
    t: Function,
    initialValues?: formValues
};

/**
 * A {@link formValidator} that checks that a value is set (i.e. not null, undefined, or "").
 *
 * @type {formValidator}
 */
export const validateRequired = (value: any, allValues: formValues, props: formProps) =>
    ((value || value === false || value === 0) && !(Array.isArray(value) && value.length === 0))
        ? undefined
        : props.t("cuda.validation.required");

/**
 * Generates a {@link formValidator} that checks that the value is an array, and validates each entry in the array against the
 * provided validators. Optionally also checks that all entries are unique.
 *
 * The {@link formValidator} returns the error message of the first failed validation (if any).
 *
 * @function
 * @param {Array.<formValidator>} validators an array of validators to run on each entry in the array.
 * @param {boolean} unique if true, validates that each entry in the array is unique. Default is false.
 * @returns {formValidator} a form validation function.
 */
export const validateArray = (validators: Validator | Validator[], unique: boolean = false) => (value: Array<any>, allValues: formValues, props: formProps) => {
    const allValidators = Array.isArray(validators) ? validators : [validators];
    // Loop over both values and validators, and get errors (if any)
    const error: [] = [];
    value && value.some((arrayValue: any, index: number) => allValidators.some((validator) => {
        const validationResult = validator(arrayValue, allValues, props);
        validationResult && set(error, `[${index}]`, validationResult);
    }));

    if (error.length > 0) {
        return error;
    }

    // Check for duplications (if uniqueness is required)
    if (unique && value) {
        const uniqueValues = uniqBy(value, (arrayValue) => typeof arrayValue === "string" ? arrayValue.toLowerCase() : arrayValue);

        if (uniqueValues.length !== value.length) {
            return props.t("cuda.validation.duplicate");
        }
    }
};

/**
 * This is a memo-ised version of {@link validateArray}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {Array.<formValidator>} validators an array of validators to run on each entry in the array.
 * @param {boolean} unique if true, validates that each entry in the array is unique. Default is false.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateArrayMemo = (validators: (Validator)[], unique?: boolean) => React.useMemo(() => validateArray(validators, unique), useDeepCompareMemoize([validators, unique]));

/**
 * Generates a {@link formValidator} function for checking the value is a valid string that matches the provided regular
 * expression. If it fails the check, the provided errorMessage is translated and returned.
 *
 * @function
 * @param {regex} regex a regular expression to use to test the value.
 * @param {string} errorMessage the error string (or a translation key) to return if the regex test fails.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateRegex = (regex: RegExp, errorMessage: string) => (value: string | {}, allValues: formValues, props: formProps) => {
    if (!value || (isString(value) && value.match(regex))) {
        return undefined;
    }
    return props.t(errorMessage);
};

/**
 * This is a memo-ised version of {@link validateRegex}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {regex} regex a regular expression to use to test the value.
 * @param {string} errorMessage the error string (or a translation key) to return if the regex test fails.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateRegexMemo = (regex: RegExp, errorMessage: string) => React.useMemo(() => validateRegex(regex, errorMessage), [regex.toString(), errorMessage]);

/**
 * Generates a {@link formValidator} function for checking the value is a valid string that DOES NOT match the provided regular
 * expression. If it fails the check, the provided errorMessage is translated and returned.
 *
 * @function
 * @param {regex} regex a regular expression to use to test the value. This should match strings that are NOT desired.
 * @param {string} errorMessage the error string (or a translation key) to return if the regex test passes.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateNegativeRegex = (regex: RegExp, errorMessage: string) => (value: RegExp | string | {}, allValues: formValues, props: formProps) => {
    if (!value || (isString(value) && !value.match(regex))) {
        return undefined;
    }
    return props.t(errorMessage);
};

/**
 * This is a memo-ised version of {@link validateNegativeRegex}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {regex} regex a regular expression to use to test the value. This should match strings that are NOT desired.
 * @param {string} errorMessage the error string (or a translation key) to return if the regex test passes.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateNegativeRegexMemo = (regex: RegExp, errorMessage: string) => React.useMemo(() => validateNegativeRegex(regex, errorMessage), [regex.toString(), errorMessage]);

/**
 * A {@link formValidator} that checks the value is a string consisting of only alphabetically or numerical characters.
 *
 * @type {formValidator}
 */
export const validateAlphaNumeric = validateRegex(/^[a-zA-Z0-9]+$/, "cuda.validation.alphaNumeric");

/**
 * A {@link formValidator} that checks the value is a valid IPv4 address.
 *
 * @implements {formValidator}
 */
export const validateIpv4 = validateRegex(ipv4Regex, "cuda.validation.ip");

/**
 * A {@link formValidator} that checks the value is a valid Private IPv4 address.
 *
 * @implements {formValidator}
 */
export const validatePrivateIpv4 = validateRegex(privateIpv4Regex, "cuda.validation.privateIp");

/**
 * A {@link formValidator} that checks the value is a valid CIDR network.
 *
 * @type {formValidator}
 */
export const validateCIDR = validateRegex(ipv4CIDRRegex, "cuda.validation.cidr");

/**
 * A {@link formValidator} that checks the value is a valid Private IPv4 CIDR 10.x.x.x/[8-32] address.
 *
 * @implements {formValidator}
 */
export const validatePrivate10CIDR = validateRegex(privateIpv4CIDR10Regex, "cuda.validation.privateIpCidr");

/**
 * A {@link formValidator} that checks the value is a valid Private IPv4 CIDR 172.16.x.x/[12-32] address.
 *
 * @implements {formValidator}
 */
export const validatePrivate172CIDR = validateRegex(privateIpv4CIDR172Regex, "cuda.validation.privateIpCidr");

/**
 * A {@link formValidator} that checks the value is a valid Private IPv4 CIDR 192.x.x.x/[16-32] address.
 *
 * @implements {formValidator}
 */
export const validatePrivate192CIDR = validateRegex(privateIpv4CIDR192Regex, "cuda.validation.privateIpCidr");

/**
 * A {@link formValidator} that checks the value is a valid IPv4 address or CIDR network.
 *
 * @type {formValidator}
 */
export const validateIpv4CIDR = (value: string, allValues: formValues, props: formProps) => {
    if (validateIpv4(value, allValues, props) && validateCIDR(value, allValues, props)) {
        return props.t("cuda.validation.ipCidr");
    }
    return undefined;
};

/**
 * A {@link formValidator} that checks the value is a valid Private IPv4 CIDR address.
 *
 * @type {formValidator}
 */
export const validatePrivateIpv4CIDR = (value: string, allValues: formValues, props: formProps) => {
    if (validatePrivate10CIDR(value, allValues, props) && validatePrivate172CIDR(value, allValues, props) && validatePrivate192CIDR(value, allValues, props)) {
        return props.t("cuda.validation.privateIpCidr");
    }
    return undefined;
};

/**
 * A {@link formValidator} that checks the value is a valid email address.
 *
 * @type {formValidator}
 */
export const validateEmail = validateRegex(emailRegex, "cuda.validation.email");

/**
 * A {@link formValidator} that checks the value is a valid domain.
 *
 * @type {formValidator}
 */
export const validateDomain = validateRegex(domainRegex, "cuda.validation.domain");

/**
 * A {@link formValidator} that checks the value is a valid domain, where wildcards are allowed.
 *
 * @type {formValidator}
 */
export const validateWildcardDomain = validateRegex(wildcardDomainRegex, "cuda.validation.wildcardDomain");

/**
 * Generates a {@link formValidator} form validations function that checks the value is at least
 * the provided number of characters long.
 *
 * @function
 * @param {number} min the minimum permitted value.
 * @returns {formValidator} a form validations function.
 */
export const validateMinValue = (min: number) => (value: number | string, allValues: formValues, props: formProps) => {
    if (value && value < min) {
        return props.t("cuda.validation.valueMin", {min});
    }
};

/**
 * This is a memo-ised version of {@link validateMinValue}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {number} min the minimum permitted value.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateMinValueMemo = (min: number) => React.useMemo(() => validateMinValue(min), [min]);

/**
 * Generates a {@link formValidator} form validations function that checks the value is at least
 * the provided number of characters long.
 *
 * @function
 * @param {number} max the maximum permitted value.
 * @returns {formValidator} a form validations function.
 */
export const validateMaxValue = (max: number) => (value: number | string, allValues: formValues, props: formProps) => {
    if (value && value > max) {
        return props.t("cuda.validation.valueMax", {max});
    }
};
/**
 * This is a memo-ised version of {@link validateMaxValue}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {number} max the maximum permitted value.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateMaxValueMemo = (max: number) => React.useMemo(() => validateMaxValue(max), [max]);

/**
 * A form validations function for validating that a input value is either an integer, or a string representation of an integer.
 *
 * i.e "1", 1, 0, "0", "101" all pass, but 3.23, "0.23" and "023things" all fail.
 *
 * @type {formValidator}
 */
export const validateInt = (value: number | string | {
    [key: string]: number | string
}, allValues: formValues, props: formProps) => {
    if (!isEmpty(value) && typeof value === "string") {
        return validateRegex(numberRegex, "cuda.validation.int")(value, allValues, props);
    } else if (!isEmpty(value) && !Number.isInteger(Number(value))) {
        return props.t("cuda.validation.int");
    }
};

/**
 * Generates a {@link formValidator} form validations function that checks the value is at least
 * the provided number of characters long.
 *
 * @function
 * @param {number} length the maximum character length the value should have.
 * @returns {formValidator} a form validations function.
 */
export const validateMaxLength = (length: number) => (value: string, allValues: formValues, props: formProps) => {
    if (!isEmpty(value) && value.length > length) {
        return props.t("cuda.validation.lengthMax", {length});
    }
};
/**
 * This is a memo-ised version of {@link validateMaxLength}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {number} length the maximum character length the value should have.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateMaxLengthMemo = (length: number) => React.useMemo(() => validateMaxLength(length), [length]);

/**
 * Generates a {@link formValidator} function that checks the value is at least
 * the provided number of characters long.
 *
 * @function
 * @param {number} length the minimum character length the value should have.
 * @returns {formValidator} a form validations function.
 */
export const validateMinLength = (length: number) => (value: string, allValues: formValues, props: formProps) => {
    if (!isEmpty(value) && value.length < length) {
        return props.t("cuda.validation.lengthMin", {length});
    }
};
/**
 * This is a memo-ised version of {@link validateMinLength}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {number} length the minimum character length the value should have.
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateMinLengthMemo = (length: number) => React.useMemo(() => validateMinLength(length), [length]);

/**
 * Generates a {@link formValidator} function that checks the date string is on or before the provided date
 *
 * @param {Date} latestDate the latest date that is allowed
 * @return {formValidator} a form validations function.
 */
export const validateDateOnBefore = (latestDate?: Date) => (value: Date | string, allValues: formValues, props: formProps) => {
    const compareDate = parseISO(value.toString());

    if (latestDate && isAfter(compareDate, latestDate)) {
        return props.t("cuda.validation.dateOnBefore", {date: format(latestDate, "yyyy-MM-dd HH:mm")});
    }
};
/**
 * This is a memo-ised version of {@link validateDateOnBefore}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {Date} latestDate the latest date that is allowed
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateDateOnBeforeMemo = (latestDate: Date) => React.useMemo(() => validateDateOnBefore(latestDate), [latestDate]);

/**
 * Generates a {@link formValidator} function that checks the date string is on or after the provided date
 *
 * @param {Date} earliestDate the earliest allowed date
 * @return {formValidator} a form validations function.
 */
export const validateDateOnAfter = (earliestDate?: Date) => (value: Date | string, allValues: formValues, props: formProps) => {
    const compareDate = parseISO(value.toString());

    if (earliestDate && isBefore(compareDate, earliestDate)) {
        return props.t("cuda.validation.dateOnAfter", {date: format(earliestDate, "yyyy-MM-dd HH:mm")});
    }
};
/**
 * This is a memo-ised version of {@link validateDateOnAfter}. Only call this inside the render method.
 * This version prevents the unnecessary re-renders caused by dynamically generating functions that are then used as props.
 *
 * @function
 * @param {Date} earliestDate the earliest allowed date
 * @returns {formValidator} a memo-ised version of the form validations function.
 */
export const validateDateOnAfterMemo = (earliestDate: Date) => React.useMemo(() => validateDateOnAfter(earliestDate), [earliestDate]);

/**
 * Checks that a given string is not an IP address.
 *
 * @function
 * @param value
 * @returns {boolean} returns false if the string is formatted as an IP address.
 */
export const notIp = (value: number | string) => isString(value) ? !!value.match(/[^0-9./]/) : false;

export const getRawError: any = (error: { message?: string }) => {
    if (typeof error?.message === "string") {
        try {
            return JSON.parse(error.message);
        } catch (err) {
            return error.message;
        }
    }
    return error?.message || error;
};

const flatMapErrorMessage: any = (error: string | Array<string | null> | { message?: string, types?: string }) => {
    if (Array.isArray(error)) {
        return error.flatMap(flatMapErrorMessage).filter(Boolean);
    }

    if (typeof error === "object" && error !== null) {
        if (error?.hasOwnProperty("message")) {
            const parsedError = getRawError(error);
            if (parsedError) {
                return flatMapErrorMessage(parsedError);
            }
        }
        const errorObject = typeof error?.types === "object" ? error.types : error;
        return Object.values(errorObject).flatMap(flatMapErrorMessage).filter(Boolean);
    }

    return error || "";
};

/**
 * Formats a react-hook-form error object into a React node ready for displaying in a component.
 *
 * @function
 * @param {Array.<any>|object|string|null|undefined} error the current error value
 * @returns {node|null}
 */
export const formatErrorMessage = (error?: string | string[] | {
    error?: string,
    message?: string,
    field?: string,
    objectField?: { value: string }
}) => {
    const flattenedError: string | [] | null = flatMapErrorMessage(error);

    if (Array.isArray(flattenedError)) {
        return uniq(flattenedError)
            .map((errorText, index) => (
                <Fragment key={errorText}>
                    {formatErrorMessage(errorText)}
                    {index !== flattenedError.length - 1 ? (<Fragment><br/></Fragment>) : null}
                </Fragment>
            ));
    }

    return flattenedError || null;
};