import {updateGlobalParams} from "../actions";
import {get, isEqual, set} from "lodash";
import {useDispatch, useSelector} from "react-redux";

export type useGlobalParamReturn = [
        any | undefined,
    (value: any) => void
];

/**
 * Hook for reading and/or managing a Global Parameter. Will cause a re-render whenever the value of the global parameter is changed.
 *
 * @function
 * @param source a dot notation path of the parameter you want to view/manage. Leave undefined if you want to view/manage all parameters (not recommended).
 * @returns [the current value of the provided global param (or undefined if not set), setter method to set the provided global param]
 */
export const useGlobalParam = (source?: string): useGlobalParamReturn => {
    const value = useSelector((state) => get(state, "GlobalParamsReducer" + (source ? "." + source : "")), isEqual);
    const dispatch = useDispatch();
    const updater = (value: any) => dispatch(updateGlobalParams(source ? set({}, source, value) : value));

    /**
     * @typedef globalParam
     * @type {array}
     * @property {*} value the current value of the target parameter.
     * @property {function} callback function to update the target parameter. The single argument (value) is used to replace the value currently stored for the target parameter.
     */
    return [value, updater];
};