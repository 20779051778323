import {REDIRECT} from "../actions";
import {push} from "react-router-redux";
import {put, take, fork} from "redux-saga/effects";

export const redirect = {
    external: (url: string) => {
        /* istanbul ignore next */
        location.href = url;
    }
};

/**
 * handler for both internal and external redirects
 *
 * listens for every redirect and pushes them as a new redux-router redirect until an external redirect is found.
 *
 * On the first external redirect that is found, the page URL is updated, And this (and all other) saga's are terminated.
 * This is to help prevent odd behaviour when JS continues to run after a page redirect (for example, on FireFox).
  */
function* redirectSagas() {
    while (true) {
        const {pathOrUrl} = yield take(REDIRECT);
        if (pathOrUrl && pathOrUrl.startsWith("http")) {
            redirect.external(pathOrUrl);
            yield put({type: "END"});
            return;
        } else {
            yield fork(function*(){
                yield put(push(pathOrUrl));
            });
        }
    }
}

export default redirectSagas;