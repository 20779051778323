import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {Typography} from "@barracuda-internal/bds-core";
import {Close} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    title: {
        padding: theme.spacing(1, 0),
        margin: theme.spacing(0, 2),
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        borderWidth: "0 0 1px"
    },
    titleText: {
        fontWeight: 400,
        fontSize: 20
    },
    closeIcon: {
        float: "right"
    },
    dialogContent: {
        padding: 0,
        width: (props: { form?: boolean }) => props.form && 680 || undefined,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"
    }
});
const useStyles = makeOverrideableStyles("DialogBody", styles);

export interface DialogBodyProps extends StyledComponentProps<typeof styles> {
    /**
     * children to render as the dialog content.
     */
    children?: React.ReactNode | (React.ReactNode | null)[] | null,
    /**
     * if true, the width is set to 600px, as appropriate for a typical form. Otherwise, width is set unset (and so is based on content size).
     */
    form?: boolean,
    /**
     * callback. If provided, a "close" icon button is added to the top right of the dialog title. This callback is called when the close icon
     * button is clicked.
     *
     * @function
     */
    onClose?: () => void,
    /**
     * text to be displayed in dialog title bar.
     */
    title?: string,
    /**
     * Not used by this component, but can be provided to set the menu entry title when used as a child to a ButtonDialog.
     */
    label?: string,
    /**
     * Not used by this component, but can be provided to set the menu entry icon when used inside as a child to ButtonDialog.
     */
    icon?: React.ReactNode
}

/**
 * Renders a title bar and formatted area for content, perfect for use in Dialogs.
 *
 * Additionally it renders a close button if an onClose method is provided.
 */
export const DialogBody = (props: DialogBodyProps) => {
    const {children, onClose, title} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <React.Fragment>
            <DialogTitle className={classes.title}>
                {title && (
                    <Typography
                        className={classes.titleText}
                        component="span"
                    >
                        {translate(title)}
                    </Typography>
                )}
                {onClose && (
                    <IconButton
                        size="small"
                        className={classes.closeIcon}
                        onClick={onClose}
                        aria-label="close"
                        disableRipple
                        disableTouchRipple
                    >
                        <Close id="cuda-icon-close"/>
                    </IconButton>
                )}
            </DialogTitle>
            {/* @ts-ignore as its showing ErrorBoundary as a non-valid JSX element */}
            <ErrorBoundary>
                <DialogContent className={classes.dialogContent}>
                    {children}
                </DialogContent>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export default DialogBody;